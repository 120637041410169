<template>
  <div>
    <h1 class="text-left">My Orders</h1>
    <div>
      <b-table class="col-12" striped hover :items="transactions" :fields="fields">
        <template #cell(#)="row">
          {{ row.index }}
        </template>
        <template #cell(createdAt)="row">
          {{ row.item.createdAt | moment('DD.MM.YYYY - HH:mm') }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import TransactionDataService from "../services/TransactionDataService";
import router from "../router";

export default {
  name: "MyOrders",
  data(){
    return {
      transactions: [],
      fields: ['#',
        {
          key: 'setTopBoxType.Name',
          label: 'Set-Top-Box',
        },
        {
          key: 'MacAddress',
          label: 'MAC Address'
        },
        {
          key: 'PvrNumber',
          label: 'PVR Ticket',
        },
        {
          key: 'createdAt',
          label: 'Purchased At'
        }
      ],
    };
  },
  computed: {

  },
  methods: {
    getUserTransactions(){
      console.log("GET USER TRANSACTIONS!");
      let config = { 'headers': { 'x-access-token': this.$store.getters.accessToken } };
      TransactionDataService.getAllFromUser(config)
          .then(response => {
            console.log(response.data);
            this.transactions = response.data;
          })
          .catch(e => {
            console.log(e);
            console.log(e.response);
            if (e.response.status === 401) {
              router.push({name: 'home'});
            }
          })
    }
  },
  mounted() {
    console.log("MOUNTED!");
    this.getUserTransactions();
  }
}
</script>

<style scoped>

</style>
